import React, { FC, useEffect, useMemo, useState } from 'react';
import Input from '../components/bootstrap/forms/Input';
import Icon from '../components/icon/Icon';
import Tooltips from '../components/bootstrap/Tooltips';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popovers from '../components/bootstrap/Popovers';
import Spinner from '../components/bootstrap/Spinner';
import moment from 'moment';
import { ValidateUrl, getGroupFromCookie, getValueFromToken } from '../App/Constants';
import Alert, { AlertHeading } from '../components/bootstrap/Alert';
import classNames from 'classnames';
import { dashboardMenu } from '../menu';
import SubHeader, { SubHeaderLeft } from '../layout/SubHeader/SubHeader';
import Breadcrumb from '../components/bootstrap/Breadcrumb';
import useDarkMode from '../hooks/useDarkMode';
import ReactApexChart from 'react-apexcharts';
import { LEADS, DH, DL, LEADERSHIP, HRDI_TEAM } from "../App/Constants";
import { getEnvVariable } from '../App/Helpers';

const cgpTeamPage = [LEADERSHIP, DH, DL, LEADS]
const cgpHRPage = [HRDI_TEAM]

export const validateUserAccess = (page: string) => {
  let access = false;
  const groupResponse = getGroupFromCookie();
  if (page == "CGPTeam")
    access = (groupResponse?.some((authority: any) => cgpTeamPage.includes(authority))) || IsQETestUser;
  else
    access = (groupResponse?.some((authority: any) => cgpHRPage.includes(authority))) || IsQETestUser;
  return access;
}

export const IsQETestUser = ():boolean => {
  if(getValueFromToken('email') === 'aatest.user@sysvine.com' && getEnvVariable() === "QE") 
    return true;
  else 
    return false;
};

export const GetTalentAcqUrl = (): string => {
  if(getEnvVariable() === "QE") 
    return "https://qe-hrvine.sysvine.com/index.php/scheduleinterviews/";
  else 
    return "https://hrvine.sysvine.com/index.php/scheduleinterviews/";
};

export const GetVIMSUrl = (): string => {
  if(getEnvVariable() === "QE") 
    return "https://qe-corpvine-vims.sysvine.com/";
  else 
    return "https://qe-corpvine-vims.sysvine.com/";
};

export const GetSkillUrl = (): string => {
  if(getEnvVariable() === "QE") 
    return "https://qe-app.skillvine.sysvine.com/";
  else 
    return "https://hrvine.sysvine.com/index.php/mycourses/";
};

// CGP BreadCrumb List based on user access
interface BCList {
  name: string;
  type: string;
};
export const CGPBCList: FC<BCList> = ({
  name, type
}) => {
  let ListData: any = null;
  if (type !== "HR") {
    ListData = [
      {
        title: dashboardMenu.myteam.text,
        to: `/${dashboardMenu.myteam.subMenu.myteamcgppage.path}`,
        disabled: true,
      },
      {
        title: dashboardMenu.myteam.subMenu.myteamcgppage.text,
        to: `/${dashboardMenu.myteam.subMenu.myteamcgppage.path}`,
      },
      {
        title: name,
        to: `/${dashboardMenu.myteam.subMenu.myteamcgppage.path}`,
      }
    ];
  } else {
    ListData = [
      {
        title: dashboardMenu.HrPage.text,
        to: `/${dashboardMenu.HrPage.subMenu.CGP.path}`,
        disabled: true,
      },
      {
        title: dashboardMenu.HrPage.subMenu.CGP.text,
        to: `/${dashboardMenu.HrPage.subMenu.CGP.path}`,
      },
      {
        title: name,
        to: `/${dashboardMenu.HrPage.subMenu.CGP.path}`,
      }
    ];
  }
  return (
    <SubHeader>
      <SubHeaderLeft>
        <Breadcrumb isToHome={false} list={ListData} />
      </SubHeaderLeft>
    </SubHeader>
  );
};
// CGP alert message control
interface AlertHD {
  message: string;
};
export const AlertHD: FC<AlertHD> = ({
  message
}) => {
  return (
    <Alert
      icon=''
      isLight
      color='primary'
      borderWidth={0}
      className='cgp-container-alert'
    >
      <AlertHeading tag='h2' className={classNames('text-sm cgp-container-alertHeading')}>
        {message}
      </AlertHeading>
    </Alert>
  );
};
// CGP input control
interface ContorlData {
  inputLabel: string;
  inputValue: string;
};
export const InputDiv: FC<ContorlData> = ({
  inputLabel,
  inputValue
}) => {
  return (
    <div className="col-md-3">
      {inputValue != null ?
        <Tooltips title={inputValue} flip={['bottom']} placement='bottom'>
          <div className="form-floating">
            <Input id="Name" type="text" className="form-control ellipsis" placeholder="Name" autoComplete="additional-name" value={inputValue} disabled />
            <label htmlFor="Name" className="form-label label-color">{inputLabel}</label>
          </div>
        </Tooltips> : <div className="form-floating">
          <Input id="Name" type="text" className="form-control ellipsis" placeholder="Name" autoComplete="additional-name" value={inputValue} disabled />
          <label htmlFor="Name" className="form-label label-color">{inputLabel}</label>
        </div>}

    </div >
  );
};
// CGP sort icon control
export const SortIcon = ({ className }: any) => {
  return (
    <Icon
      size='lg'
      className={className}
      icon='FilterList'
    />);
}
// CGP Autocomplete control
interface AutoCompleteData {
  options: any;
  inputValue: string | undefined;
  label: string | undefined;
  onChange: (event: any, value: any) => void;
};
export const AutoComplete: FC<AutoCompleteData> = ({
  options,
  inputValue,
  label,
  onChange,
}) => {
  const [DDValue, setDDValue] = useState<any>();
  useEffect(() => {
    setDDValue(inputValue);
  }, [inputValue]
  )
  const handleSelect = (event: any, value: any) => {
    onChange(event, value)
    setDDValue(value);
  };
  const selectedValue = useMemo(() => {
    return options.find((jobTitle: any) => jobTitle.value == DDValue)
  }, [DDValue, options])
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      className=' sh dd-cgg'
      options={options}
      getOptionLabel={(option: any) => option.label}
      onChange={(event, value) => handleSelect(event, value.value)}
      renderInput={(params) => <TextField {...params} label={label} />}
      value={selectedValue ? selectedValue : null}
      noOptionsText="No Matches Found"
    />
  );
};
// CGP Popover control
interface PopoverData {
  desc: string;
};
export const PopOverData: FC<PopoverData> = ({
  desc,
}) => {
  return (
    <Popovers className='cgp-popover' desc={desc} trigger='click'>
      {<Icon
        icon={'info'}
        color={'info'}
        className='btn-icon cursor-pointer cgp-container-align-popover' />}
    </Popovers>);
}
// CGP No record control
interface INoRecord {
  isLoading: boolean;
  tableRows: number;
};
export const NoRecord: FC<INoRecord> = ({
  isLoading, tableRows
}) => {
  return (
    <div>
      {
        (!isLoading && tableRows === 0) ?
          (
            <div className='text-center cpp-no-skill'>
              There is no data to show
            </div>
          ) : null
      }
      {
        isLoading &&
        <div className='cgp-overlay-box'>
          <Spinner className='text-center cgp-no-skill cgp-spinner' inButton />
        </div>}
    </div>
  );
}
// CGP Datetime format control
export const formatedDate = (date: any, includeTime: boolean = true) => {
  const dateFormat = includeTime ? 'DD-MMM-YYYY, HH:mm' : 'DD-MMM-YYYY';
  return date ? moment(date).format(dateFormat).toUpperCase() : "";
};
// CGP URL with Icon control
export const UrlwithIcon = (props: any, isDetails: boolean = false) => {
  var urls = ValidateUrl(props);
  return urls.map((url: any, index: number) => {
    const { href } = url.props;
    if (href != undefined) {
      if (href.startsWith('https://') || href.startsWith('http://')) {
        return (
          <a key={url} href={href} target='_blank' title="Link" style={{ marginLeft: '10px', strokeWidth: "10", stroke: "#0d8eb9" }} className='icon-color icon-link '>
            <Icon icon='FileLink' size='sm' />
          </a>
        );
      }
    }
  });
};
// CGP URL with word control
export const UrlwithWord = (props: any) => {
  var urls = ValidateUrl(props);
  let currentIndex = 1;
  return urls.map((url: any) => {
    const { href } = url.props;
    if (href != undefined) {
      if (href.startsWith('https://') || href.startsWith('http://')) {
        const currentLink = (
          <a key={url} href={href} target='_blank' style={{ marginRight: '8px' }}>
            {currentIndex === 1 ? 'Link' : `Link${currentIndex}`}
          </a>
        );
        currentIndex += 1;
        return currentLink;
      }
    }
    else {
      return null;
    }
  });
};
// CGP skill edit status bubble control
interface ICommonFilterTagProps {
  title: string;
  text: string;
}
export const CommonFilterTagCGP: FC<ICommonFilterTagProps> = ({ title, text }) => {
  const { darkModeStatus } = useDarkMode();

  return (
    <small
      className={` px-3 py-2 rounded-pill`}>
      <strong>{title}:</strong> {text}
      <Icon icon='ErrorCircle' color='danger' size='lg' className='ms-2 cursor-pointer' />
    </small>
  );
};
// CGP information control
interface CommentsInfo {
  comments: string | undefined;
}

export const CommentsInfo: FC<CommentsInfo> = ({ comments }) => {
  return (
    <>
      {comments && comments.length > 220 && comments.length <= 250 && (
        <label className='cgp-container-rwmessage info-size'>
          {250 - comments.length} characters remaining (Maximum: 250)
        </label>
      )}
      {
        comments && comments?.length > 250 &&
        <label className='cgp-container-commentsmessage '>You have exceeded the allowed 250 characters</label>
      }
    </>
  );
};
interface IChartData {
  data: any;
  type: string;
  page: string;
};
export const ApexChart: FC<IChartData> = ({
  data,
  type,
  page
}) => {

  let state: any = ChartOptions(data, type, page);
  if (type == "pie") {
    return (
      <ReactApexChart options={state.options} series={state.series} type="pie" height={75} />
    )
  }
  else if (type == "treemap") {
    return (
      <div id="cgp-chart">
        <ReactApexChart options={state.options} series={state.series} type="treemap" height={60} />
      </div>
    )
  }
  else {
    return (

      <div id="cgp-chart">
        <ReactApexChart options={state.options} series={state.series} type="bar" />
      </div>
    )
  }
}

export const ColorSelection = (value: string, type: string) => {
  let colorhighlight = "bg-req";
  let colorCode = '#fff';

  if (value) {
    if (value == "Requested" || value == "Primary Change Requested") {
      colorhighlight = 'bg-req';
      colorCode = '#25A5C5';
    }
    else if (value == "A" || value == "Achieved" || value == "Acquired" || value == "Primary Change HR Approved" || value == "Met Expectation") {
      colorhighlight = 'bg-ach';
      colorCode = '#079976';
    } else if (value == "B" || value == "Approved" || value == "Primary Change DH Approved") {
      colorhighlight = 'bg-app';
      colorCode = '#B8E994';
    } else if (value == "C") {
      colorhighlight = 'bg-gradeC';
      colorCode = '#FFEA2C';
    }
    else if (value == "D" || value == "In Progress" || value == "Deficient") {
      colorhighlight = 'bg-in';
      colorCode = '#FCBD1A';
    } else if (value == "E" || value == "Non-Performance") {
      colorhighlight = 'bg-non';
      colorCode = '#E98339';
    }
    else if (value == "F") {
      colorhighlight = 'bg-gradeF';
      colorCode = '#B71540';
    }
    else if (value == "Goal Met") {
      colorhighlight = 'bg-gm';
    }
    else if (value == "Primary Change DH Denied" || value == "Denied") {
      colorhighlight = 'bg-DHD';
    } else if (value == "Primary Change HR Denied" || value == "HR Denied" || value == "Far away") {
      colorhighlight = 'bg-HRD';
    }
    else if (value == "Getting Close") {
      colorhighlight = 'bg-orange';
    }
    else if (value == "N/A") {
      colorhighlight = 'bg-grey';
    }
    else if (value == "") {
      colorhighlight = 'bg-req';
    }
    return type == "HEX" ? colorCode : "cgp-container-" + colorhighlight;
  }
  else
    return type == "HEX" ? colorCode : "";
}
const ChartOptions = (data: any, type: any, page: any) => {
  const { themeStatus, darkModeStatus } = useDarkMode();

  let dataValue: any = [];
  let dataSeries: any = null;
  switch (page) {
    case "EMP":
      if (data.expectedGrade != "") {
        dataValue.push({
          name: "Expected",
          x: "Expected Grade " + data.expectedGrade,
          y: 2,
          fillColor: ColorSelection(data.expectedGrade, "HEX")
        });
      }
      if (data.currentGrade != "") {
        dataValue.push({
          name: "Current",
          x: "Current Grade " + data.currentGrade,
          y: 2,
          fillColor: ColorSelection(data.currentGrade, "HEX")
        });
      }
      if (data.nextGoalGrade != "") {
        dataValue.push({
          name: "Goal",
          x: "Goal Grade " + data.nextGoalGrade,
          y: 2,
          fillColor: ColorSelection(data.nextGoalGrade, "HEX")
        });
      }
      dataSeries = [
        {
          data: dataValue
        }
      ];
      break;
    default:
      // colorSeries = ['#25A5C5', '#FCBD1A', '#079976'];
      dataSeries = [
        {
          data: [
            {
              x: 'Requested',
              y: data.requestedCount,
              fillColor: '#25A5C5'
            }, {
              x: 'In-Progress',
              y: data.inProgressCount,
              fillColor: '#FCBD1A'
            }, {
              x: 'Acquired',
              y: data.acquiredCount,
              fillColor: '#079976'
            },
          ]
        }
      ];
      break;
  }
  let state: any = null;
  switch (type) {
    case "bar":
      state = {
        series: dataSeries,
        options: {
          // colors: colorSeries,
          chart: {
            redrawOnParentResize: true,
            type: 'bar',
            stackType: "100%",
            height: "50px",
            stacked: true,
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 1,
              dataLabels: {
                total: {
                  enabled: true,
                  position: 'center',
                  offsetX: 0,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900
                  }
                }
              }
            },
          },
          title: {
            text: undefined,
            align: 'left',
          },
          grid: {
            show: false,
          },
          stroke: {
            width: 2,
            colors: ['#fff']
          },
          xaxis: {
            type: "number",
            categories: ["Position"],
            show: false,
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }

          },
          yaxis: {
            show: false,
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }

          },
          tooltip: {
            y: {
              formatter: function (val: any) {
                return val;
              }
            },
            x: {
              show: false
            },
          },

          fill: {
            opacity: 1
          },
          legend: {
            show: false,
            height: "0px"
          }
        }
      };
      break;
    case "treemap":
      state = {
        series: dataSeries,
        // colors: colorSeries,
        options: {
          legend: {
            show: false
          },
          chart: {
            type: 'treemap',
            height: 70,
            toolbar: {
              show: false
            }
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            distributed: false,
            textAnchor: 'middle',
            borderRadius: 2,
            borderWidth: 10,
            offsetY: -3,
            formatter: function (text: any, op: any) {
              if (op.value <= 0)
                return "";
              else {
                let value = page == "EMP" ? text.slice(text.length - 1, text.length) : op.value;
                return value.length > 0 ? value : "";
              }
            },
          },
          title: {
            text: "",
            align: 'center'
          },
          tooltip: {
            theme: darkModeStatus ? 'dark' : 'light',
            y: {
              title: {
                formatter(val: any, op: any) {
                  return page == "EMP" ? val : val;
                }
              },
              formatter(val: any, op: any) {
                return page == "EMP" ? "" : val;
              }
            },
            x: {
              show: false
            },
          },
          plotOptions: {
            treemap: {
              distributed: false,
              enableShades: true,
            }
          }
        },
      }
      break;
    case "bar-st":
      state = {
        series: dataSeries,
        options: {
          legend: {
            show: false
          },
          chart: {
            type: 'treemap',
            height: "50px",
            toolbar: {
              show: false
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val: any) {
              return val;
            }
          },
          title: {
            text: undefined,
            align: 'center'
          },
          tooltip: {
            y: {
              formatter: function (val: any) {
                return "";
              }
            },
            x: {
              show: false
            },
          },
          plotOptions: {
            treemap: {
              distributed: true,
              enableShades: true
            }
          }
        },
      }
      break;
    case "pie":
      state = {
        series: [data.requestedCount, data.inProgressCount, data.achievedCount],
        options: {
          colors: ['#25A5C5', '#FCBD1A', '#079976'],
          chart: {
            width: '100%',
            height: '100%',
            type: 'pie',
          },
          labels: ["Requested", "In-Progress", "Achieved"],
          theme: {
            monochrome: {
              enabled: false
            }
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false,
                // offset: -5
              }
            }
          },
          title: {
            text: undefined,
            align: 'left',
          },
          dataLabels: {
            enabled: false,
            formatter: function (val: any) {
              return val;
            }
          },
          legend: {
            show: false
          }
        }
      }
      break;
    default:
      break;
  }

  return state
}