import axios from 'axios';
import Cookies from 'js-cookie';
import DomPurify from 'dompurify';
import moment from 'moment';

import IProcessVineLogs from '../model/IProcessVineLogs';
import { AuditPeriod, KPILog, KpiActivity } from './types';
import { serviceURL } from '../types';
import { KPIStatus } from './constants';
import IMainKpi from '../model/MainKPI';
import { DH, DL,PRVN_LEADS, getGroupFromCookie } from '../App/Constants';

export function getAuditPeriodFromDBValue(auditPeriodFromDB: string): AuditPeriod {
	switch (auditPeriodFromDB) {
		case 'quarterly':
			return AuditPeriod.QUARTERLY;
		case 'halfyearly':
			return AuditPeriod.HALFYEARLY;
		case 'yearly':
			return AuditPeriod.YEARLY;
		case 'na':
			return AuditPeriod.NA;
		default:
			return AuditPeriod.INVALID;
	}
}

export function mapIProcessvineLogsToKPILog(logHistory: IProcessVineLogs): KPILog {
	return {
		id: logHistory.id || 0,
		accountId: logHistory.accountId || 0,
		requestedBy: logHistory.requestedby || 0,
		requestedDate:
			logHistory.requestedOn !== null && logHistory.requestedOn !== undefined
				? new Date(logHistory.requestedOn)
				: new Date(),
		requestedScore: logHistory.requestedScore || 0,
		approvedBy: logHistory.approvedby || 0,
		approvedDate:
			logHistory.approvedOn !== null && logHistory.approvedOn !== undefined
				? new Date(logHistory.approvedOn)
				: new Date(),
		approvedScore: logHistory.approvedScore || 0,
		finalizedBy: logHistory.finalizedby || 0,
		finalizedDate:
			logHistory.finalizedOn !== null && logHistory.finalizedOn !== undefined
				? new Date(logHistory.finalizedOn)
				: new Date(),
		finalizedScore: logHistory.finalizedScore || 0,
		status: logHistory.status || 0,
	};
}

export function getLastActivityAndScoreAndDateOfTheKPI(log: KPILog): [KpiActivity, number, Date] {
	// log.status: 5 -> rejected, 4 -> finalized, 2 -> approved, 1 -> requested

	if (log.status === 5) {
		return [KpiActivity.REJECTED, 0, log.finalizedDate];
	} else if (log.status === 4) {
		return [KpiActivity.FINALIZE, log.finalizedScore, log.finalizedDate];
	} else if (log.status === 2) {
		return [KpiActivity.APPROVE, log.approvedScore, log.approvedDate];
	} else if (log.status === 1) {
		return [KpiActivity.REQUEST, log.requestedScore, log.requestedDate];
	} else {
		return [KpiActivity.NOT_STARTED, log.requestedScore, log.requestedDate];
	}
}

function getCurrentQuarter(date: Date): number {
	const month: number = date.getMonth() + 1; // Months are zero-based

	if (month >= 1 && month <= 3) {
		return 1;
	} else if (month >= 4 && month <= 6) {
		return 2;
	} else if (month >= 7 && month <= 9) {
		return 3;
	} else {
		return 4;
	}
}

function getCurrentHalfYear(date: Date): number {
	const month: number = date.getMonth() + 1; // Months are zero-based

	if (month >= 1 && month <= 6) {
		return 1; // First half of the year
	} else {
		return 2; // Second half of the year
	}
}

export function getCurrentAuditPeriodDateRange(auditPeriod: AuditPeriod): Date[] {
	const currentYear = new Date().getFullYear();

	const quarterRanges: any = {
		1: [new Date(currentYear, 0, 1), new Date(currentYear, 2, 31)],
		2: [new Date(currentYear, 3, 1), new Date(currentYear, 5, 30)],
		3: [new Date(currentYear, 6, 1), new Date(currentYear, 8, 30)],
		4: [new Date(currentYear, 9, 1), new Date(currentYear, 11, 31)],
	};

	const halfYearRanges: any = {
		1: [new Date(currentYear, 0, 1), new Date(currentYear, 5, 30)],
		2: [new Date(currentYear, 6, 1), new Date(currentYear, 11, 31)],
	};

	const yearRange: [Date, Date] = [new Date(currentYear, 0, 1), new Date(currentYear, 11, 31)];

	if (auditPeriod === AuditPeriod.QUARTERLY) {
		return quarterRanges[getCurrentQuarter(new Date())];
	} else if (auditPeriod === AuditPeriod.HALFYEARLY) {
		return halfYearRanges[getCurrentHalfYear(new Date())];
	} else if (auditPeriod === AuditPeriod.YEARLY) {
		return yearRange;
	}

	return [];
}

export function isThisDateFallsUnderGivenAuditPeriod(
	date: Date,
	auditPeriod: AuditPeriod,
): boolean {
	let start;
	let end;

	if (auditPeriod === AuditPeriod.QUARTERLY) {
		[start, end] = getCurrentAuditPeriodDateRange(AuditPeriod.QUARTERLY);
	} else if (auditPeriod === AuditPeriod.HALFYEARLY) {
		[start, end] = getCurrentAuditPeriodDateRange(AuditPeriod.HALFYEARLY);
	} else if (auditPeriod === AuditPeriod.YEARLY) {
		[start, end] = getCurrentAuditPeriodDateRange(AuditPeriod.YEARLY);
	} else {
		// It must be N/A
		return true;
	}

	return start <= date && end >= date;
}

export function getKPIActivityColorClassName(activity: KpiActivity): string {
	switch (activity) {
		case KpiActivity.REQUEST:
			return 'bg-kpi-request';
		case KpiActivity.APPROVE:
			return 'bg-kpi-approve';
		case KpiActivity.FINALIZE:
			return 'bg-kpi-finalize';
		case KpiActivity.REJECTED:
			return 'bg-kpi-rejected';
		case KpiActivity.NOT_STARTED:
			return 'bg-kpi-not_started';
	}
}

export function getActivityReadableName(activity: KpiActivity, pastTense = true): string {
	switch (activity) {
		case KpiActivity.REQUEST:
			return 'Request' + (pastTense ? 'ed' : '');
		case KpiActivity.APPROVE:
			return 'Approve' + (pastTense ? 'd' : '');
		case KpiActivity.FINALIZE:
			return 'Finalize' + (pastTense ? 'd' : '');
		case KpiActivity.REJECTED:
			return 'Reject' + (pastTense ? 'ed' : '');
		case KpiActivity.NOT_STARTED:
			return 'Not Started';
	}
}

export function getActivityPersonReadableName(activity: KpiActivity): string {
	switch (activity) {
		case KpiActivity.REQUEST:
			return 'Requestor';
		case KpiActivity.APPROVE:
			return 'Approver';
		case KpiActivity.FINALIZE:
			return 'Finalizer';
		case KpiActivity.REJECTED:
			return 'Finalizer';
		case KpiActivity.NOT_STARTED:
			return '';
	}
}

export function formatDateToSysvineStandard(date: any) {
	return date ? moment(date).format('DD-MMM-YYYY HH:mm').toUpperCase() : ' - ';
}

export function getAgoTime(date: any) {
	return moment(date)?.tz('Asia/Calcutta', true).fromNow();
}

export function getScoreColor(score: number) {
	if (score >= 0 && score <= 29) {
		return 'score-red';
	} else if (score >= 30 && score < 59) {
		return 'score-orange';
	} else if (score >= 60 && score < 89) {
		return 'score-yellow';
	} else if (score >= 90) {
		return 'score-green';
	} else {
		// N/A
		return 'score-grey';
	}
}

// This will remove the events and attributes of html. Check this for reference https://github.com/cure53/DOMPurify
export const sanitizeDom = (__html: string) => {
	return DomPurify.sanitize(__html);
};

// Take out links in string and make it <a> tag for clickable
export function urlify(text: string) {
	var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
	var santizedText = sanitizeDom(text);
	return santizedText.replace(urlRegex, function (url, b, c) {
		// adds https:// if the url simply starts with www.
		var url2 = c == 'www.' ? 'http://' + url : url;
		return '<a href="' + url2 + '" target="_blank">' + url + '</a>';
	});
}

export async function getProject(params: any) {
	const response = await axios.get(`${serviceURL}/api/progressvine/project`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
		params: {
			accountName: params?.accountName,
			projectName: params?.projectName,
		},
	});
	return response;
}

export function isCommentsChanged(oldComment: string, newComment: string): boolean {

	if(oldComment.startsWith('<p>')) {
		return oldComment !== newComment
	} else {
		const formattedOldComment = `<p>${oldComment}</p>`;
		return formattedOldComment !== newComment;
	}
}

export function getKPIStatusFromString(kpiStatus: string | null): KPIStatus | null {
	if(kpiStatus === null) {
		return null;
	}

	switch(kpiStatus) {
		case 'requested':
			return KPIStatus.REQUESTED;
		case 'approved':
			return KPIStatus.APPROVED;
		case 'finalized':
			return KPIStatus.FINALIZED;
		case 'rejected':
			return KPIStatus.REJECTED;
		case 'not_started':
			return KPIStatus.NOT_STARTED;
		default:
			return null;
	}
}

export function getKPIStatusAsNumber(kpiStatus: KPIStatus | null): number | null {
	if(kpiStatus === null) {
		return null;
	}

	switch(kpiStatus) {
		case KPIStatus.REQUESTED:
			return 1;
		case KPIStatus.APPROVED:
			return 2;
		case KPIStatus.FINALIZED:
			return 4;
		case KPIStatus.REJECTED:
			return 5;
		case KPIStatus.NOT_STARTED:
			return 0;
		default:
			return null;
	}
}

export function getKpiStatusByGroup() {
	//auomatically changes the filter based of user access
	let groups = getGroupFromCookie();
	switch (true) {
		case groups && groups.some((group: string) => group.includes(PRVN_LEADS)):
			return KPIStatus.APPROVED;
		case validateFilterStatus(groups):
			return KPIStatus.REQUESTED;
	}
	
}

export function createATreeOfParentAndChildKPIs(kpis: IMainKpi[]) {
	const map = new Map();
	const parentKPIsWithChildKPIs: any[] = [];

	// Bringing all the parent KPIs first, so that children for those KPIs will be created first
	kpis.sort((a: any, b: any) => (!a.parentId ? -1 : 1));

	kpis.forEach((item: IMainKpi) => {
		if (!item.parentId && item.level === 0) {
			parentKPIsWithChildKPIs.push({
				...item,
				children: [],
			});
			map.set(item.id, parentKPIsWithChildKPIs[parentKPIsWithChildKPIs.length - 1]);
		} else {
			const parent = map.get(item.parentId);
			if (parent && parent.children) {
				parent.children.push(item);
				parent.children.sort((a: any, b: any) => a.kpiOrder - b.kpiOrder);
				map.set(item.id, parent.children[parent.children.length - 1]);
			}
		}
	});

	parentKPIsWithChildKPIs.sort((a, b) => a.kpiOrder - b.kpiOrder);

	return parentKPIsWithChildKPIs;
}

export function calculateAverageScoreOfKPISet(kpiLogs: IProcessVineLogs[]): number {
	let score = 0;
	let totalNumberOfFinalizedKPIs = 0;

	for(let i=0; i<kpiLogs.length; i++) {
		const kpiLog = kpiLogs[i];
		const finalizedScore = (kpiLog.finalizedScore === undefined) ? -1: (kpiLog.finalizedScore || 0);

		if(finalizedScore !== -1) {
			score += finalizedScore;
			totalNumberOfFinalizedKPIs += 1;
		}
	}

	if(totalNumberOfFinalizedKPIs === 0) {
		return -1;
	}

	return score / totalNumberOfFinalizedKPIs;
}

export function calculateAverageScoreOfAParentKPI(parentKPIId: number, kpis: IMainKpi[], kpiLogs: IProcessVineLogs[] = []): number {
	const kpisToCalculate: number[] = [];

	// ? NOTE: Used forEach instead of combination of filter and map due to performance reasons
	kpis.forEach(kpi => {
		if(kpi.parentId === parentKPIId) {
			if(kpi && kpi.id) {
				kpisToCalculate.push(kpi.id);
			}
		}
	});

	const kpiLogsToCalcualte: IProcessVineLogs[] = kpisToCalculate.map(kpiId => {
		const kpiLog = kpiLogs.find(log => log.kpi === kpiId);

		if(kpiLog === undefined) {
			return {
				finalizedScore: 0,
			};
		}

		return kpiLog;
	});

	return calculateAverageScoreOfKPISet(kpiLogsToCalcualte);
}

export function handleKPIScorePercentage(score: number): string {
	if(score < 0) {
		return 'N/A';
	} else {
		return `${Math.round(score)}%`;
	}
}

const filterStatusAllowedList = [DH, DL]

export const validateFilterStatus = (authorityList:any) => {
    return authorityList?.some((authority:any) => filterStatusAllowedList.includes(authority));
}

export const ALL = 'all';
