import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProcessVinePage from '../../ProgressVine/ProgressVine';
import contents from '../../routes/contentRoutes';
import EmployeeCGPPage from '../../CGP/CGPEmp';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
console.log("Routes", contents);
const ContentRoutes = () => {
    return (
        <Routes>
            {contents.map((page) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Route key={page.path} {...page} />
            ))}
            <Route path="/" element={<Navigate replace to="/myjourney/mycgp" />} />
            {/* <Route path='/' element={<ProcessVinePage />} /> */}
            <Route path='*' element={<PAGE_404 />} />
            {/* When displaying the dashboard page,  uncomment the above line */}

        </Routes>
    );
};

export default ContentRoutes;